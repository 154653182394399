/* eslint-disable */
import clamp from 'lodash.clamp';
import sum from 'lodash.sum';
import uniq from 'lodash.uniq';
import debounce from 'lodash.debounce';

export const LEFT = 'Left';
export const RIGHT = 'Right';
export const UP = 'Up';
export const DOWN = 'Down';

function getDirection(absX, absY, deltaX, deltaY) {
  if (absX > absY) {
    if (deltaX > 0) {
      return LEFT;
    }
    return RIGHT;
  } else if (deltaY > 0) {
    return UP;
  }
  return DOWN;
}

function rotateXYByAngle(pos, angle) {
  if (angle === 0) return pos;
  const angleInRadians = (Math.PI / 180) * angle;
  const x =
    pos[0] * Math.cos(angleInRadians) + pos[1] * Math.sin(angleInRadians);
  const y =
    pos[1] * Math.cos(angleInRadians) - pos[0] * Math.sin(angleInRadians);
  return [x, y];
}

function getPosition(e, rotationAngle) {
  const { clientX, clientY } = e.touches ? e.touches[0] : e;
  return rotateXYByAngle([clientX, clientY], rotationAngle);
}

function addEvents(el, eventHandlers) {
  eventHandlers.forEach(([e, h, o]) => el.addEventListener(e, h, o));
  return () =>
    eventHandlers.forEach(([e, h, o]) => el.removeEventListener(e, h, o));
}

const swipeable = (node, options) => {
  const minDeltaY = (options && options.deltaY) || 0;
  const minDeltaX = (options && options.deltaX) || 0;
  const rotationAngle = (options && options.rotationAngle) || 0;
  const trackTouch = (options && options.trackTouch) || true;
  const trackMouse = (options && options.trackMouse) || false;
  const trackHover = (options && options.trackHover) || false;
  const eventElement = (options && options.eventElement) || node;
  const stopPropagation = (options && options.stopPropagation) || false;
  const preventDefault = (options && options.preventDefault) || false;

  let isEnable = false;
  let rafVilosity = null;

  let isClering = false;

  let initial = [0, 0],
    timeStart,
    swiping,
    dir,
    deltaX,
    deltaY,
    velocity;
  let clearHandlers = () => {};
  let clearStartHandlers = () => {};

  const startEvents = [];

  let prefDX = 0;
  let prefDY = 0;
  let difSwipeFrameX = 0;
  let difSwipeFrameY = 0;
  // let prefTime = null;

  let testVilocityArray = [];
  let testDashArrayY = [];
  let testDashArrayX = [];

  const frame = 16.5;

  const timerVilosityDown = () => {
    difSwipeFrameX = Math.abs(deltaX) - Math.abs(prefDX);
    difSwipeFrameY = Math.abs(deltaY) - Math.abs(prefDY);

    prefDX = deltaX;
    prefDY = deltaY;

    const tVelocity = Math.hypot(difSwipeFrameX, difSwipeFrameY) / frame;

    testVilocityArray.push(tVelocity);
    testDashArrayY.push(Math.abs(difSwipeFrameY));
    testDashArrayX.push(Math.abs(difSwipeFrameX));

    if (isEnable) {
      rafVilosity = requestAnimationFrame(timerVilosityDown);
    }
  };

  const handleMousemove = (e, type) => {
    e.preventDefault();

    if (isClering) {
      initial = getPosition(e, rotationAngle);
      timeStart = e.timeStamp || 0;
    }

    const [rX, rY] = getPosition(e, rotationAngle);
    const [sX, sY] = initial;

    const newDeltaX = sX - rX;
    const newDeltay = sY - rY;

    const deltaDirX = newDeltaX - deltaX;
    const deltaDirY = newDeltay - deltaY;

    deltaX = newDeltaX;
    deltaY = newDeltay;
    const absX = Math.abs(deltaX);
    const absY = Math.abs(deltaY);

    const dX = deltaDirX > 0 ? -1 : 1;
    const stickyX = clamp(absX, 0, minDeltaX) * dX;
    const dY = deltaDirX > 0 ? -1 : 1;
    const stickyY = clamp(absX, 0, minDeltaX) * dY;

    deltaX += stickyX;
    deltaY += stickyY;
    if (absX < minDeltaX && !swiping) return;
    if (absY < minDeltaY && !swiping) return;

    // let time = (e.timeStamp || 0) - timeStart;
    // velocity = Math.sqrt(absX * absX + absY * absY) / (time || 1);
    const nextDir = getDirection(absX, absY, deltaDirX, deltaDirY);

    if (nextDir !== dir) {
      timeStart = e.timeStamp || 0;
    }

    dir = nextDir;

    const detail = {
      event: e,
      eventType: type,
      position: [rX, rY],
      initial,
      deltaX,
      deltaY,
      velocity,
      dir,
    };

    if (options.swiping) {
      options.swiping(detail);
    }

    // node.dispatchEvent(new CustomEvent('swiping', { detail }));

    swiping = true;
  };

  const handleMouseup = (e) => {
    // e.preventDefault();

    const vilSmall = testVilocityArray.slice(
      testVilocityArray.length - 5,
      testVilocityArray.length
    );
    velocity = sum(vilSmall) / (vilSmall.length || 1);

    const dashSmallY = uniq(
      testDashArrayY.slice(testDashArrayY.length - 3, testDashArrayY.length)
    );
    difSwipeFrameY = clamp(sum(dashSmallY) / (dashSmallY.length || 1), 0, 40);

    difSwipeFrameY = dir === DOWN ? difSwipeFrameY * -1 : difSwipeFrameY;

    const dashSmallX = uniq(
      testDashArrayX.slice(testDashArrayX.length - 3, testDashArrayX.length)
    );
    difSwipeFrameX = clamp(sum(dashSmallX) / (dashSmallX.length || 1), 0, 40);
    difSwipeFrameX = dir === RIGHT ? difSwipeFrameX * -1 : difSwipeFrameX;

    isEnable = false;
    cancelAnimationFrame(rafVilosity);

    const detail = {
      event: e,
      initial,
      deltaX,
      deltaY,
      dir,
      velocity,
      difSwipeFrameY,
      difSwipeFrameX,
    };
    if (options.swiped) {
      options.swiped(detail);
    }

    // node.dispatchEvent(new CustomEvent('swiped', { detail }));
    clearHandlers();
  };

  const proxyTouch = (e) => handleMousemove(e, 'touch');
  const proxyHover = (e) => handleMousemove(e, 'hover');

  const handleMousedown = (e) => {
    if (stopPropagation) {
      e.stopPropagation();
    }

    if (preventDefault) {
      e.preventDefault();
    }
    // e.preventDefault();

    initial = getPosition(e, rotationAngle);
    timeStart = e.timeStamp || 0;
    velocity = null;
    // prefTime = null;
    swiping = false;
    deltaX = 0;
    deltaY = 0;
    prefDX = 0;
    prefDY = 0;
    testVilocityArray = [];
    testDashArrayY = [];
    testDashArrayX = [];

    isEnable = true;
    rafVilosity = requestAnimationFrame(timerVilosityDown);

    if (options.swipestart) {
      options.swipestart(initial);
    }
    // node.dispatchEvent(new CustomEvent('swipestart', { detail: { initial }}));

    const moveHandlers = [
      ['mousemove', proxyHover],
      ['mouseup', handleMouseup],
      ['touchend', handleMouseup],
      ['touchcancel', handleMouseup],
      ['touchmove', proxyTouch, { passive: false }],
    ];

    clearHandlers = addEvents(window, moveHandlers);
  };

  if (trackTouch)
    startEvents.push(['touchstart', handleMousedown, { passive: false }]);
  if (trackMouse) startEvents.push(['mousedown', handleMousedown]);
  if (trackHover) startEvents.push(['mousemove', proxyHover]);

  clearStartHandlers = addEvents(eventElement, startEvents);

  const disableClear = debounce(() => {
    isClering = false;
  }, 100);

  const __clear = () => {
    cancelAnimationFrame(rafVilosity);
    isClering = true;
    testVilocityArray = [];
    testDashArrayY = [];
    testDashArrayX = [];
    disableClear();
  };

  return {
    clear: __clear,
    onMouseUp: handleMouseup,
    destroy: () => {
      cancelAnimationFrame(rafVilosity);
      clearStartHandlers();
    },
  };
};

export default swipeable;
