import * as THREE from 'three';

import { mix } from 'utils';

import particleTexture from 'assets/particle.jpg';
import vertexShader from './shaders/vertex.glsl';
import fragmentShader from './shaders/fragment.glsl';

const COUNT = 2500;

export default class Particles {
  constructor(size = { x: 100, y: 150, z: 100 }) {
    this.particleGeo = new THREE.PlaneBufferGeometry(1, 1);
    this.geo = new THREE.InstancedBufferGeometry();
    this.geo.instanceCount = COUNT;
    this.geo.setAttribute(
      'position',
      this.particleGeo.getAttribute('position')
    );
    this.geo.index = this.particleGeo.index;

    const pos = new Float32Array(COUNT * 3);

    const minRadius = 0.1;
    const maxRadius = 2;

    for (let i = 0; i < COUNT; i += 1) {
      const theta = Math.random() * 2 * Math.PI;
      const r = mix(minRadius, maxRadius, Math.random());

      const x = r * Math.sin(theta) * size.x;
      const y = Math.random() - 0.5 * size.y;
      const z = r * Math.cos(theta) * size.z;

      pos.set([x, y, z], i * 3);
    }

    this.geo.setAttribute(
      'pos',
      new THREE.InstancedBufferAttribute(pos, 3, false)
    );

    this.material = new THREE.ShaderMaterial({
      side: THREE.DoubleSide,
      transparent: true,
      depthTest: false,
      uniforms: {
        uTexture: { value: new THREE.TextureLoader().load(particleTexture) },
        time: { value: 10 },
        uColor: { value: new THREE.Vector3(1, 1, 1) },
      },
      vertexShader,
      fragmentShader,
    });

    this.points = new THREE.Mesh(this.geo, this.material);
  }

  onUpdateColor = (newColor) => {
    this.material.uniforms.uColor.value = newColor;
  };

  onUpdate = () => {
    this.material.uniforms.time.value += 0.005;
  };
}
