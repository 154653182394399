/* eslint-disable */
import App from './App';
import GsapSectionScroll from './GsapSectionScroll';

const isDebug = false;

class ThreeJsScene {
  constructor(idContainer, idScroll, idTrigger, sectionScroll = 5) {
    this.rootNode = document.querySelector(idContainer);
    this.scrollNode = document.querySelector(idScroll);
    this.triggerNode = document.querySelector(idTrigger);

    new GsapSectionScroll(this.scrollNode, sectionScroll);
    const threeApp = new App(this.scrollNode, this.triggerNode, isDebug);

    this.rootNode.appendChild(threeApp.renderer.domElement);
    threeApp.renderer.domElement.style.position = 'absolute';
    threeApp.renderer.domElement.style.top = '0';
    threeApp.renderer.domElement.style.left = '0';
    threeApp.renderer.domElement.style.width = '100%';
    threeApp.renderer.domElement.style.height = '100vh';

    if (!isDebug) {
      threeApp.renderer.domElement.style.pointerEvents = 'none';
    } else {
      this.scrollNode.style.pointerEvents = 'none';
    }
  }
}

// '#scene-scroll'

window.ThreeJsScene = ThreeJsScene;
