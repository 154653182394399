import * as THREE from 'three';
import { gsap } from 'gsap';
import clamp from 'lodash.clamp';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import test3 from 'assets/scene.glb';
import image1 from 'assets/img1.jpg';
import image3 from 'assets/img3.jpg';
import top1 from 'assets/top1.jpg';
import top3 from 'assets/top3.jpg';

import { glbLoader, texturesLoader, rotateObject } from 'utils';

import Screen from './Screen';
import CoreApp from './CoreApp';
import Particles from './Particles';

gsap.registerPlugin(ScrollTrigger);

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const animationSteps = [
  {
    camera: {
      x: 126.45094074537809,
      y: 103.82944276224974 + 10,
      z: 175.50245535789367,
    },
    lookAt: {
      x: -35,
      y: -11 + 10,
      z: 0,
    },
    color: '#E5692D',
    color2: '#3C0E02',
    bgImage: 0,
    lightPosition: {
      x: 42,
      y: 22,
      z: 20,
    },
    headRotate: {
      x: -10,
      ease: 'power2.inOut',
    },
  },
  {
    camera: {
      x: -1,
      y: -1.4193186172997114 + 10,
      z: 168,
    },
    lookAt: {
      x: -1,
      y: -4.4 + 10,
      z: 0,
    },
    color: '#E5692D',
    color2: '#3C0E02',
    bgImage: 0,
    lightPosition: {
      x: 42,
      y: 22,
      z: 20,
    },
    headRotate: {
      y: 0,
      x: 9,
      ease: 'power2.inOut',
    },
  },
  {
    camera: {
      x: -2.356495962889134,
      y: 0.6194512840324862 + 10,
      z: 83,
    },
    lookAt: {
      x: 0,
      y: -2 + 10,
      z: 0,
    },
    color: '#E5692D',
    color2: '#3C0E02',
    bgImage: 0,
    lightPosition: {
      x: 42,
      y: 22,
      z: 20,
    },
    headRotate: {
      y: -23,
      x: 3,
      ease: 'power3.inOut',
    },
  },
  {
    camera: {
      x: -2.4105358977940456,
      y: 1.0731916220346338 + 10,
      z: 80,
    },
    lookAt: {
      x: 0,
      y: -2 + 10,
      z: 0,
    },
    color: '#9B9CDD',
    color2: '#2B225E',
    bgImage: 1,
    lightPosition: {
      x: -51,
      y: 22,
      z: 20,
    },
    headRotate: {
      y: 30,
      x: 20,
      ease: 'power2.inOut',
    },
  },
  {
    camera: {
      x: -7.296610670069127,
      y: 1.9434356553009469 + 10,
      z: 235,
    },
    lookAt: {
      x: 0,
      y: -4.4 + 10,
      z: 0,
    },
    color: '#9B9CDD',
    color2: '#2B225E',
    bgImage: 1,
    lightPosition: {
      x: -51,
      y: 22,
      z: 20,
    },
    headRotate: {
      y: 0,
      x: 0,
      ease: 'power3.out',
    },
  },
];

const defaultAnimSettings = {
  duration: 1,
  ease: 'power0',
};

// THREE.PointLight.prototype.addSphere = function () {
//   this.sphere = new THREE.Mesh(
//     new THREE.SphereGeometry(2, 16, 16),
//     new THREE.MeshBasicMaterial({ color: this.color })
//   );
//   this.add(this.sphere);
// };

export default class AppGame extends CoreApp {
  constructor(scrollNode, triggerNode, isDebug) {
    super();

    this.isDebug = isDebug;
    this.scrollNode = scrollNode;
    this.triggerNode = triggerNode;
    this.isLoaded = false;
    this.glbScene = null;

    this.mousePosition = {
      x: 0,
      y: 0,
    };

    this.imageTextures = {
      screen: [],
      topScreen: [],
    };

    this.animation = {
      progress: 0,
      cameraPosition: animationSteps[0].camera,
      cameraLookAt: animationSteps[0].lookAt,
      color: animationSteps[0].color,
      color2: animationSteps[0].color2,
      lightPosition: animationSteps[0].lightPosition,
      headRotate: animationSteps[0].headRotate,
      elements: {
        head: null,
        screen: null,
        topScreen: null,
      },
    };

    this.onLoadScene().then(() => {
      this.onAddObjects();
      this.onCreateTimeLine();
      this.isLoaded = true;
    });

    if (this.isDebug) {
      this.camera.position.set(
        126.45094074537809,
        103.82944276224974 + 10,
        175.50245535789367
      );
      new OrbitControls(this.camera, this.renderer.domElement);
    }

    window.addEventListener('mousemove', this.onMouseMove);
  }

  onMouseMove = (e) => {
    gsap.to(this.mousePosition, {
      x: clamp((e.clientX * 2) / this.wWidth - 0.5, -1, 1),
      y: clamp((e.clientY * 2) / this.wHeight - 0.5, -1, 1),
      overwrite: true,
      duration: 2,
      ease: 'power3.out',
    });
  };

  onCreateTimeLine = () => {
    const tl = gsap.timeline({
      scrollTrigger: {
        start: 'top top',
        end: 'bottom 100%',
        scroller: this.scrollNode,
        trigger: this.triggerNode,
        scrub: true,
      },
    });

    animationSteps.forEach((step, i) => {
      if (i === 0) {
        this.camera.currentLookAt = {
          ...step.lookAt,
        };
        return;
      }

      tl.to(this.animation.cameraPosition, {
        ...step.camera,
        ...defaultAnimSettings,
      });
      tl.to(
        this.animation.cameraLookAt,
        {
          ...step.lookAt,
          ...defaultAnimSettings,
        },
        '<'
      );

      tl.to(
        this.animation.lightPosition,
        {
          ...step.lightPosition,
          ...defaultAnimSettings,
          ease: 'power1.out',
        },
        '<'
      );

      tl.to(
        this.animation,
        {
          color: step.color,
          color2: step.color2,
          progress: i,
          ...defaultAnimSettings,
        },
        '<'
      );
      tl.to(
        this.animation.headRotate,
        {
          ...step.headRotate,
          duration: 1,
        },
        // '<+0.06'
        '<'
      );
    });
  };

  onAddObjects = () => {
    this.group = new THREE.Group();
    const animationScreens = animationSteps.map((el) => el.bgImage);

    this.particles = new Particles();

    this.animation.elements.screen = new Screen(
      this.glbScene.getObjectByName('merge4_1'),
      this.imageTextures.screen,
      animationScreens
    );

    this.animation.elements.topScreen = new Screen(
      this.glbScene.getObjectByName('merge4_3'),
      this.imageTextures.topScreen,
      animationScreens
    );

    this.animation.elements.head = this.glbScene.getObjectByName('Group-head');
    const bike = this.glbScene.getObjectByName('merge4_12');

    this.animation.elements.head.position.z = 36.22;
    this.animation.elements.head.position.x = -2.304;

    bike.material.roughness = 0.5;

    const potolok = this.glbScene.getObjectByName('merge4_3');
    const meshPotolok = new THREE.Mesh(
      potolok.geometry,
      new THREE.MeshBasicMaterial({
        color: 0x000000,
        side: THREE.DoubleSide,
      })
    );

    meshPotolok.position.y = 10;

    //
    // const head = this.glbScene.getObjectByName('merge4_16');
    // const jacket = this.glbScene.getObjectByName('merge4_13');
    // const pants = this.glbScene.getObjectByName('merge4_12');
    // const coloring = 'rgb(115, 115, 115)';
    //
    // jacket.material.color = new THREE.Color(coloring);
    // head.material.color = new THREE.Color(coloring);
    // pants.material.color = new THREE.Color(coloring);

    this.glbScene.position.y = 10;
    this.animation.elements.screen.mesh.position.y = 10;
    this.animation.elements.topScreen.mesh.position.y = 9.8;

    this.group.add(meshPotolok);
    this.group.add(this.particles.points);
    this.group.add(this.glbScene);
    this.group.add(this.animation.elements.screen.mesh);
    this.group.add(this.animation.elements.topScreen.mesh);
    this.scene.add(this.group);
  };

  onLoadScene = async () => {
    this.imageTextures.screen = await texturesLoader([image1, image3]);
    this.imageTextures.topScreen = await texturesLoader([top1, top3]);
    const glb = await glbLoader(test3);
    this.glbScene = glb.scene;
  };

  onUpdateAnimationElements = () => {
    const { color } = this.animation;

    // this.lights.ambientLight.color = new THREE.Color(color);

    // this.glbScene.getObjectByName('OctaneLight_2').color = new THREE.Color(
    //   color
    // );

    // this.lights.pointScreen.color = new THREE.Color(color);
    // this.lights.pointScreen2.color = new THREE.Color(color2);
    //
    // this.lights.pointLight3.color = new THREE.Color(color2);
    // this.lights.pointAmbian2.color = new THREE.Color(color2);
    //
    // this.groundMirror.material.uniforms.circleColor.value = new THREE.Color(
    //   color
    // );
    //

    //
    // this.lights.pointScreen.position.set(
    //   lightPosition.x,
    //   lightPosition.y,
    //   lightPosition.z
    // );

    if (!this.isDebug) {
      this.onUpdateCameraPosition(this.animation);
    }

    this.onUpdateHeadRotation(this.animation);
    this.onScreenUpdate(this.animation);
    this.particles.onUpdateColor(new THREE.Color(color));
  };

  onScreenUpdate = ({ elements, progress }) => {
    elements.screen.onUpdateProgress(progress);
    elements.topScreen.onUpdateProgress(progress);
  };

  onUpdateHeadRotation = ({ elements, headRotate }) => {
    rotateObject(elements.head, headRotate.x, headRotate.y, headRotate.z);
  };

  onUpdateCameraPosition = ({ cameraLookAt, cameraPosition }) => {
    const { x, y } = this.mousePosition;

    const nextLookAtX = cameraLookAt.x + 5 * x;
    const nextLookAtY = cameraLookAt.y + 5 * y;
    const distLookAtx = (nextLookAtX - this.camera.currentLookAt.x) * -0.0074;
    const distLookAty = (nextLookAtY - this.camera.currentLookAt.y) * -0.009;

    this.camera.position.set(
      cameraPosition.x,
      cameraPosition.y,
      cameraPosition.z
    );
    this.camera.currentLookAt = {
      x: nextLookAtX,
      y: nextLookAtY,
      z: cameraLookAt.z,
    };
    this.camera.lookAt(nextLookAtX, nextLookAtY, cameraLookAt.z);

    this.group.rotation.y += distLookAtx - this.group.rotation.y * 0.045;
    this.group.rotation.z += distLookAtx - this.group.rotation.z * 0.045;
    this.group.rotation.x += distLookAty - this.group.rotation.x * 0.045;
  };

  onUpdate = () => {
    if (this.isLoaded) {
      this.onUpdateAnimationElements();
      this.particles.onUpdate();
    }
  };

  onDestroy = () => {
    window.removeEventListener('mousemove', this.onMouseMove);
  };
}
