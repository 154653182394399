import * as THREE from 'three';

import { fract } from 'utils';

import vertexShader from './shaders/vertex.glsl';
import fragmentShader from './shaders/fragment.glsl';

export default class Screen {
  constructor(parentElement, textures, indexMap) {
    this.progress = 0;
    this.textures = textures || [];
    this.indexMap = indexMap || this.textures.map((_, i) => i);

    // prettier-ignore
    const material = new THREE.ShaderMaterial({
      uniforms: {
        progress: { value: 0, type: 'f' },
        texture1: { value: this.textures[this.indexMap[0]] || THREE.Texture.DEFAULT_IMAGE },
        texture2: { value: this.textures[this.indexMap[1]] || THREE.Texture.DEFAULT_IMAGE },
      },
      vertexShader,
      fragmentShader,
    });

    this.mesh = new THREE.Mesh(parentElement.geometry, material);
    parentElement.visible = false; // eslint-disable-line
  }

  getIndex = (index) => {
    const { length } = this.indexMap;
    return (index + length) % length;
  };

  // prettier-ignore
  onSetTextures = (cur, next, progress = 0) => {
    this.mesh.material.uniforms.texture1.value = this.textures[this.indexMap[cur]];
    this.mesh.material.uniforms.texture2.value = this.textures[this.indexMap[next]];
    this.mesh.material.uniforms.progress.value = progress;
  };

  onUpdateProgress = (newProgress) => {
    this.progress = newProgress;
    const curIndex = this.getIndex(Math.trunc(this.progress));
    const nextIndex = this.getIndex(curIndex + 1);

    this.onSetTextures(curIndex, nextIndex, fract(this.progress));
  };
}
