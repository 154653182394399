import { TextureLoader } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';

const texLoader = new TextureLoader();
const loader = new GLTFLoader();

export const rotateObject = (object, degreeX = 0, degreeY = 0, degreeZ = 0) => {
  object.rotation.x = THREE.MathUtils.degToRad(degreeX);
  object.rotation.y = THREE.MathUtils.degToRad(degreeY);
  object.rotation.z = THREE.MathUtils.degToRad(degreeZ);
};

export const mix = (x, y, a) => {
  return x * (1 - a) + y * a;
};

// x - Math.floor(x);
export const fract = (number) => number - Math.trunc(number);

export const calculateSizeImage = (wWidth, wHeight, orig, cover) => {
  const { naturalWidth: targetW, naturalHeight: targetH } = orig;

  const rw = wWidth / targetW;
  const rh = wHeight / targetH;
  let r;

  if (cover) {
    r = rw > rh ? rw : rh;
  } else {
    r = rw < rh ? rw : rh;
  }

  return {
    left: (wWidth - targetW * r) >> 1, // eslint-disable-line
    top: (wHeight - targetH * r) >> 1, // eslint-disable-line
    width: targetW * r,
    height: targetH * r,
    scale: r,
    uvRate: {
      x: (targetW * r) / wWidth,
      y: (targetH * r) / wHeight,
    },
  };
};

export const textureLoader = (url) =>
  new Promise((resolve) => {
    texLoader.load(url, (texture) => {
      resolve(texture);
    });
  });

export const texturesLoader = (urlArray) =>
  Promise.all(urlArray.map(textureLoader));

export const glbLoader = (url, cbLoadProgress) =>
  new Promise((resolve, reject) => {
    loader.load(
      url,
      resolve,
      (xhr) => {
        if (cbLoadProgress) {
          cbLoadProgress((xhr.loaded / xhr.total) * 100);
        }
      },
      reject
    );
  });
